import React, { useState, useEffect } from "react";
import {

    Box,
    Button,
    Typography,
    Stack,
    Card,
} from "@mui/material";
import { Link } from 'react-router-dom';

function AboutPage(props) {
    const { userId, db, showAbout } = props;
    const [showAboutForm, setShowAboutForm] = useState(true)

    const handleClose = () => {
        setShowAboutForm(false);
    };

    function handleLogin() {

    }

    return (

        // (showAbout && showAboutForm) && (
        <div>
            <Card
                elevation={10}
                sx={{
                    width: "70%",
                    // width: "650px",
                    marginTop: "1em",
                    marginBottom: ".5em",
                    marginLeft: "10px",
                    marginRight: "4px",
                }}
            >
                <Stack direction="row">
                    <Typography
                        component={"div"}
                        color="blue"
                        sx={{
                            fontStyle: "regular",
                            fontSize: "13px",
                            textAlign: "justify",
                            paddingLeft: "3px",
                            paddingRight: "15px",
                        }}
                    >
                        <p>
                            <strong>Trupollz</strong> is a hobby wbesite, and lets anyone create polls, education quizzes and to rate
                            anything quickly, honestly and fairly, without being conditioned by pollsters, lobbyists etc., who control access
                            and push their own narrative.
                        </p>
                        <p>Use Trupollz to get <strong>real-time Customer Experience</strong> feedback or conduct Anonymous Employee Surveys with <strong>live</strong> results.</p>
                        <p>Rate web content (articles, videos), or...anything.</p>
                        <p>Publish your <strong>analysis or opinion,</strong> see what others think about it.</p>
                        <p>
                            To register/login, press Login. After save/submit, give it a couple of seconds, the database update can take time.
                            Select a button, and hit it again to un-select it. Again, you may need a couple of seconds after a key press for the database to update. Double-click to select, as needed, Also, set your Chrome or other browser Zoom at 75-80% for optimum viewing.
                        </p>
                        <p>Current Release: 8</p>
                        <p>For questions/comments/bugs, write to admin@trupollz.com</p>
                    </Typography>
                    {/* <IconButton onClick={handleClose}> */}
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Link to='/'>
                            <Button
                                variant="contained"
                                // backgroundcolor="#333"
                                // endIcon={<SendIcon backgroundColor="grey" />}
                                onClick={handleClose}
                                style={{
                                    border: "none",
                                    width: "100px",
                                    marginLeft: "10px",
                                    marginTop: '4px',
                                    marginRight: '10px',
                                    marginBottom: '8px',
                                    borderRadius: 15,
                                    color: "black",
                                    backgroundColor: "#D1D1D1",
                                }}
                            >
                                Close

                            </Button>
                        </Link>
                    </Box>
                    {/* </IconButton> */}
                </Stack>
            </Card>
        </div>
        // )
    )
}

export default AboutPage